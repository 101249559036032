/* Global styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scoped styles */
.signup-container {
  display: flex;
  height: 100vh;
}

.left-pane {
  flex: 1;
  background-color: #e0f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-pane {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group {
  margin-bottom: 20px;
}

/* Form button styles */
.form-button {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
}

/* Modal close button styles */
.modal-close {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
}

.modal-close:focus {
  outline: none;
  box-shadow: none;
}
